import AcUnitIcon from "@mui/icons-material/AcUnit";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import introJs from "intro.js/intro";
import {
  MaterialReactTable,
  MRT_ActionMenuItem,
  useMaterialReactTable,
} from "material-react-table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  depotURL,
  designCaseURL,
  projectURL,
  resourceURL,
  simulationURL,
  tariffURL,
} from "../../static/constants/backendRoutes";
import materialReactTableOptions from "../../static/constants/defaultMaterialReactTableOptions";
import { depotTourOptions } from "../../static/constants/tourOptions";
import UseAuth from "../auth/useAuth";
import { ProjectStepper } from "../secondary/steppers";
import TourBeacon from "../secondary/tourBeacon";
import { unitTemperature } from "../secondary/unitConversions";
import { errorHandler, useQuery } from "../utils";
import DepotResourceIcon, { DesignCaseIcons } from "./depotResourceIcon";
import ClimateDetails from "./dialogs/climateDetails";
import DepotDialog from "./dialogs/depotDialog";
import DepotMap from "./maps/depotMap";

function Depot() {
  const projectId = useQuery().get("projectId");
  //todo: look into consolidating projectLookup and allProjects states
  const [data, setData] = useState([]);
  const [projectLookup, setProjectLookup] = useState(undefined);
  const [simulationLookup, setSimulationLookup] = useState(undefined);
  const [designCaseLookups, setDesignCaseLookups] = useState({
    primary: {},
    all: {},
  });

  const [vehicleList, setVehicleList] = useState([]);
  const [tariffList, setTariffList] = useState([]);

  const [showTable, setShowTable] = useState(true); //true for show table, false for show map
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [rowUpdateData, setRowUpdateData] = useState({});

  const [chartDialog, setChartDialog] = useState(false);
  const [chartData, setChartData] = useState(null);

  const { accessRights, organizationMemo } = useContext(DataContext);

  const { snackBarElement } = useContext(SnackBarContext);

  const depotDataFiltered = useMemo(
    () =>
      data.filter(
        (depot) =>
          !organizationMemo?.id || depot.organization_id == organizationMemo.id
      ),
    [data, organizationMemo]
  );

  useEffect(() => {
    /**
     * checks if user is logged in,
     * retrieves created projects and associated depots
     * from the backend, and stores them both
     */
    function fetchData() {
      if (!UseAuth("get")) {
        window.location.assign("/login");
        return;
      }

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      //fetches project-specific depots, or all depots if no ID is specified
      fetch(
        projectId
          ? `${depotURL}?project_id_list=${projectId}`
          : `${depotURL}?organization_id=-${
              Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
            }`,
        { method: "GET", headers: headers }
      )
        .then((res) => {
          if (res.ok) res.json().then(({ data: depots }) => setData(depots));
          else errorHandler(res, snackBarElement, "Failed to retrieve depots");
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Failed to retrieve depots",
            "error",
            5000
          );
        })
        .finally(() => {
          if (table) table.setState((prev) => ({ ...prev, isLoading: false }));
        });

      //retrieve all projects (Probably overkill to do this for individual projects)
      fetch(
        `${projectURL}?organization_id_list=-${
          Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
        }`,
        { method: "GET", headers: headers }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then(({ data: projects }) => {
              let project_lookup = {};
              projects.forEach((project) => {
                //creates a dictionary of the project ID and their names
                project_lookup[project.id] = project;
              });
              setProjectLookup(project_lookup);
            });
          } else
            errorHandler(
              response,
              snackBarElement,
              "Failed to retrieve project data"
            );
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Failed to retrieve projects",
            "error",
            5000
          );
        });

      //get all tariff resources associated with a project; or, if no project is specified, all tariffs associated with the user
      ///NOTE: Consider updating Depot backend to provide tariff count
      /// details with the "get all depots" queries, so that we can eleminate this query
      fetch(
        `${tariffURL}?type=2&project_id=${
          projectId ??
          `&organization_id=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`
        }`,
        { method: "GET", headers: headers }
      )
        .then((response) => {
          if (response.ok)
            response.json().then(({ data: tariffs }) => setTariffList(tariffs));
          else errorHandler(response, snackBarElement);
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Failed to retrieve tariffs",
            "error",
            5000
          );
        });

      //get all vehicle resources associated with a project; or, if no project is specified, all resources associated with the user
      ///NOTE: Consider updating Depot backend to provide ResourceInventory count
      /// details with the "get all depots" queries, so that we can eleminate this query
      fetch(
        `${resourceURL}?type=2&project_id=${
          projectId ??
          `&organization_id=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`
        }`,
        { method: "GET", headers: headers }
      )
        .then((response) => {
          if (response.ok)
            response.json().then(({ data }) => setVehicleList(data));
          else errorHandler(response, snackBarElement);
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Failed to retrieve vehicle data",
            "warning",
            5000
          );
        });

      //fetch all design cases, and set the lookup tables
      fetch(
        projectId
          ? `${designCaseURL}?project_id=${projectId}`
          : `${designCaseURL}?organization_id=-${
              Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
            }`,
        { method: "GET", headers: headers }
      )
        .then((res) => {
          if (res.ok)
            res.json().then(({ data: design_case_list }) => {
              const primaryLookup = {},
                allLookup = {};
              design_case_list.forEach(
                ({ is_primary, depot_id, key_metrics }) => {
                  allLookup[depot_id] = key_metrics;
                  if (is_primary) primaryLookup[depot_id] = key_metrics;
                }
              );
              setDesignCaseLookups({ primary: primaryLookup, all: allLookup });
            });
          else errorHandler(res, snackBarElement, "Error getting design cases");
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Failed to retrieve design cases",
            "error",
            5000
          );
        });

      //below fetch is only used to populate simulation lookup, for the depot map tooltips
      fetch(
        `${simulationURL}?organization_id=-${
          Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
        }`,
        { method: "GET", headers: headers }
      )
        .then((res) => {
          if (res.ok)
            res.json().then(({ data: simulation_list }) => {
              const simulationLookup = {};
              simulation_list.forEach((simulation) => {
                if (simulationLookup[simulation.depot_id]) {
                  simulationLookup[simulation.depot_id].num_analyses++;
                  simulationLookup[simulation.depot_id].num_complete +=
                    simulation.completed;
                } else
                  simulationLookup[simulation.depot_id] = {
                    num_analyses: 1,
                    num_complete: +simulation.completed,
                  };
              });
              setSimulationLookup(simulationLookup);
            });
          else errorHandler(res, snackBarElement, "Error getting simulations");
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Failed to retrieve simulations",
            "error",
            5000
          );
        });
    }

    fetchData();
  }, [projectId, organizationMemo.lookup]);

  /**
   * deletes a depot
   * @param {import("material-react-table").MRT_TableInstance<never>} table
   * @param {number[]} depot_id_list
   * @param {() => void} closeMenu a helper function provided by the table's delete handler
   */
  const handleDelete = useCallback(
    (table, depot_id_list, closeMenu) => {
      table.setState((prev) => ({ ...prev, isLoading: true }));

      fetch(depotURL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("get")}`,
        },
        body: JSON.stringify({ depot_id_list }),
      })
        .then((response) => {
          if (response.ok) {
            const updatedDepots = data.filter(
              (depot) => !depot_id_list.includes(depot.id)
            );
            setData(updatedDepots);
            snackBarElement.current.displayToast("Depot Deleted!");
            closeMenu();
          } else errorHandler(response, snackBarElement);
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Something is not right, try again",
            "error"
          );
        })
        .finally(() =>
          table.setState((prev) => ({ ...prev, isLoading: false }))
        );
    },
    [data]
  );

  //DELETE modal
  const openDeleteConfirmModal = (table, row, closeMenu) => {
    if (
      window.confirm(
        "Are you sure you want to delete this depot?\nAny associated analyses will also be deleted."
      )
    )
      handleDelete(table, [row.original.id], closeMenu);
  };

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => {
      const projectFilterOptionsSet = new Set(
        data.map((row) => +row.project_id)
      ); // create a set of all project ids, for use in the filter of project id dropdown
      return [
        //format for the table
        { header: "Name", accessorKey: "name" },
        {
          header: "Address",
          id: "address",
          accessorFn: (row) =>
            `${row.address1}${row.address2} ${row.city}, ${row.state_code} ${row.zipcode} ${row.country}`,
          Cell: ({ row }) => (
            <>
              {row.original.address1}
              <br />
              {row.original.address2 && (
                <>
                  {row.original.address2}
                  <br />
                </>
              )}
              {row.original.city}, {row.original.state_code}{" "}
              {row.original.zipcode}
              <br />
              {row.original.country}
            </>
          ),
        },
        {
          header: "Current Resources",
          id: "current_resources",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          Cell: ({ row }) => (
            <DepotResourceIcon
              tariffList={tariffList}
              vehicleList={vehicleList}
              depot_id={row.original.id}
            />
          ),
        },
        {
          header: "Climate",
          id: "climate",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          Cell: ({ row }) => {
            const unit = projectLookup?.[row.original.project_id]?.unit || 1;
            return (
              <Button
                id="climateData" // for tour selection
                variant="outlined"
                className="btn"
                onClick={() => {
                  setChartDialog(true);
                  setChartData({ ...row.original, unit });
                }}
              >
                &nbsp;
                <WbSunnyIcon style={{ color: "gold" }} />
                &nbsp;
                {Math.round(
                  unitTemperature(
                    row.original.climate.annual_temperature.maximum_temperature,
                    unit
                  )
                )}
                {unit == 1 ? <>&#8451;</> : <>&#8457;</>}
                &nbsp;&nbsp;to&nbsp;&nbsp;
                {Math.round(
                  unitTemperature(
                    row.original.climate.annual_temperature.minimum_temperature,
                    unit
                  )
                )}
                {unit == 1 ? <>&#8451;</> : <>&#8457;</>}&nbsp;
                <AcUnitIcon style={{ color: "skyblue" }} />
                &nbsp;
              </Button>
            );
          },
        },
        {
          header: "Resources",
          id: "resource_inventory",
          Cell: ({ row }) => (
            <Button
              variant="outlined"
              className="btn"
              component={Link}
              to={`/resource-inventory?depotId=${row.original.id}`}
            >
              Edit/Add
            </Button>
          ),
        },
        {
          header: "Project",
          accessorKey: "project_id",
          accessorFn: (row) =>
            projectLookup?.[row.project_id]?.name ?? "Loading...",
        },
        {
          header: "Primary Design Case",
          id: "design_case",
          Cell: ({ row }) => (
            <DesignCaseIcons
              depot_id={row.original.id}
              designCaseLookups={designCaseLookups}
            />
          ),
        },
        {
          header: "Analyses",
          id: "view_analyses",
          Cell: ({ row }) => (
            <Button
              variant="outlined"
              className="btn"
              size="small"
              component={Link}
              to={`/simulations?depotId=${row.original.id}`}
            >
              View
            </Button>
          ),
        },
      ];
    },
    [designCaseLookups.primary, projectLookup, tariffList, vehicleList]
  );

  const handleTourStart = () => {
    let tour = introJs();
    setTimeout(() => tour.setOptions(depotTourOptions()).start(), [100]);
  };

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data: depotDataFiltered,
    columns: columns,
    initialState: {
      ...materialReactTableOptions().initialState,
      columnVisibility: {
        project_id: !projectId,
        design_case: false,
        view_analyses: false,
      },
      pagination: { pageIndex: 0, pageSize: 5 },
      isLoading: true,
    },

    //global filter settings
    enableGlobalFilter: true, // enables global filter

    //create row settings
    renderTopToolbarCustomActions: () => (
      <>
        <span style={{ width: "100%" }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            className="btn create-new-btn"
            variant="outlined"
            sx={{ px: 3 }}
            onClick={() => {
              setRowUpdateData({
                project_id: projectId || "",
                country_code: projectLookup?.[projectId]?.country_code ?? "",
              });
              setShowEditDialog(true);
            }}
            startIcon={<AddIcon />}
            disabled={!projectLookup} //|| !accessRights.projects.create_depot}
          >
            New Depot
          </Button>
          <TourBeacon
            title="Tutorial"
            onClick={handleTourStart}
            hidden={!depotDataFiltered?.length} //|| !accessRights.projects.create_depot
          />
        </Box>
      </>
    ),

    //edit and delete row settings
    enableRowActions: true, // makes the row actions icon appear
    renderRowActions: undefined, // overwrites the common renderRowActions value, so that the renderRowActions dropdown becomes available
    renderRowActionMenuItems: ({ table, row, closeMenu, ...props }) => [
      <MRT_ActionMenuItem
        icon={<EditIcon />}
        key="edit"
        label="Edit"
        table={table}
        onClick={() => {
          setRowUpdateData(row.original);
          setShowEditDialog(true);
        }}
        // disabled={!accessRights.projects.edit_depot}
      />,
      <MRT_ActionMenuItem
        icon={<DeleteIcon />}
        key="delete"
        label="Delete"
        table={table}
        onClick={() => openDeleteConfirmModal(table, row, closeMenu)}
        // disabled={!accessRights.projects.delete_depot}
      />,
    ],
  });

  return (
    <div>
      <br />
      <br />
      <ProjectStepper stepNum={2} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {projectId ? (
            <>
              Current Project:{" "}
              <span className="header-green">
                {projectLookup?.[projectId]?.name ?? "Loading..."}
              </span>
            </>
          ) : (
            <>All Depots Summary</>
          )}
        </Typography>{" "}
        <Stack direction="row" spacing={1}>
          <Chip
            label="Table"
            variant={showTable ? "filled" : "outlined"}
            disabled={showTable}
            onClick={() => setShowTable(true)}
          />
          <Chip
            label="Map"
            variant={showTable ? "outlined" : "filled"}
            disabled={!showTable}
            onClick={() => setShowTable(false)}
          />
        </Stack>
      </Container>
      <br />
      {showTable ? ( //show table vs show map
        <>
          <Container fixed maxWidth="xl">
            <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
              <MaterialReactTable table={table} />
            </Paper>
          </Container>
          <br />
          <Container fixed>
            <Grid container spacing={1}>
              <Grid className="grid-left" item xs={12} sm={6}>
                <Button
                  variant="outlined"
                  className="btn"
                  component={Link}
                  to={"/project"}
                  startIcon={<ArrowBackIosNew />}
                >
                  Return To Projects
                </Button>
              </Grid>
              {projectId && (
                <Grid className="grid-right" item xs={12} sm={6}>
                  <Button
                    id="viewProjectAnalysis"
                    variant="outlined"
                    className="btn"
                    component={Link}
                    to={`/simulations?projectId=${projectId}`}
                  >
                    View Project Analyses
                  </Button>
                </Grid>
              )}
            </Grid>
          </Container>
        </>
      ) : (
        <Container>
          <Paper elevation={3}>
            <DepotMap
              simulationLookup={simulationLookup}
              projectLookup={projectLookup}
              designCaseLookups={designCaseLookups}
              tariffList={tariffList}
              vehicleList={vehicleList}
              coordinates={data}
            />
          </Paper>
        </Container>
      )}
      <ClimateDetails
        chartData={chartData}
        setChartData={setChartData}
        setChartDialog={setChartDialog}
        chartDialog={chartDialog}
        depotData={data}
        setDepotData={setData}
      />
      {/* add/edit depot dialog box */}
      <DepotDialog
        open={showEditDialog}
        setOpen={setShowEditDialog}
        presetProjectId={projectId}
        projectLookup={projectLookup}
        allDepots={data}
        setAllDepots={setData}
        rowUpdateData={rowUpdateData}
        setRowUpdateData={setRowUpdateData}
      />
    </div>
  );
}

export default Depot;
