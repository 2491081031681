import MaterialTable, { MTableToolbar } from "@material-table/core";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../contexts/dataContext";
import { localDb } from "../contexts/localDb";
import { SnackBarContext } from "../contexts/snackBarContext";
import TYPE_STRINGS from "../static/constants/TYPE_STRINGS";
import {
  cloneSimulationURL,
  designCaseURL,
  projectURL,
  simulationURL,
  toggleLockSimulationURL,
} from "../static/constants/backendRoutes";
import localDbTableNames from "../static/constants/localDbTableNames.json";
import stepInfo, {
  assessmentStepZero,
  comparisonStepInfo,
} from "../static/constants/stepInfo";
import UseAuth from "./auth/useAuth";
import { setAnalysisCookies } from "./secondary/unitConversions";
import {
  Icons,
  clearLocalDb,
  errorHandler,
  getLocalData,
  storeLocalData,
  useQuery,
} from "./utils";

export default function Simulations() {
  const projectId = useQuery().get("projectId");
  const depotId = useQuery().get("depotId");
  const { user_role } = UseAuth("get_details");
  const [data, setData] = useState([]);
  //used to identify which row to duplicate the data of in handleDuplicateID
  const [projectLookup, setProjectLookup] = useState(undefined);
  const [duplicateId, setDuplicateId] = useState(undefined);
  //a mapping of all projects, used to find the name of the project (for the header), when the projectID is specified
  const [project, setProject] = useState(undefined);
  // determines if the table is in "loading" mode or not (becomes true when clicking Edit/resume/viewSummary)
  const [isLoading, setIsLoading] = useState(true);
  const [lockedAnalysis, setLockedAnalysis] = useState({});
  const [isDesignCaseDialogOpen, setIsDesignCaseDialogOpen] = useState(false);
  const [designCase, setDesignCase] = useState({});

  const { accessRights, analysisStepsViewMemo, organizationMemo } =
    useContext(DataContext);

  const navigate = useNavigate();

  const { snackBarElement } = useContext(SnackBarContext);
  const materialTableRef = useRef();

  const dataFiltered = useMemo(
    () =>
      data.filter(
        (sim) =>
          !organizationMemo?.id || sim.organization_id == organizationMemo.id
      ),
    [data, organizationMemo?.id]
  );

  useEffect(() => {
    /** fetch prior existing simulations */
    function fetchData() {
      if (!UseAuth("get")) {
        window.location.assign("/login"); ///TODO: should display error page
        return;
      }

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      try {
        const simulationPromise = fetch(
          `${simulationURL}?organization_id=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          { method: "GET", headers: headers }
        );
        const designCasePromise = fetch(
          `${designCaseURL}?organization_id=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          { method: "GET", headers: headers }
        );

        Promise.all([simulationPromise, designCasePromise])
          .then(async ([simulationsResponse, designCaseResponse]) => {
            if (simulationsResponse.ok && designCaseResponse.ok) {
              //combine design case and simulation responses
              let simulations = await simulationsResponse
                .json()
                .then(({ data }) => data);
              const designCases = await designCaseResponse
                .json()
                .then(({ data }) => data);
              //merge the design case and simulation objects
              designCases.forEach((designCase) => {
                const sim = simulations.find(
                  ({ id }) => id == designCase.analysis_id
                );
                Object.entries(designCase).forEach(
                  ([key, value]) => (sim[key] = value)
                );
              });

              if (projectId)
                simulations = simulations.filter(
                  (sim) => sim.project_id == projectId
                );
              else if (depotId)
                simulations = simulations.filter(
                  (sim) => sim.depot_id == depotId
                );

              setData(simulations);
            } else if (!simulationsResponse.ok) {
              errorHandler(
                simulationsResponse,
                snackBarElement,
                "Failed to fetch analyses"
              );
            } else
              errorHandler(
                designCaseResponse,
                snackBarElement,
                "Failed to fetch analyses"
              );
          })
          .catch((e) => {
            console.log(e);
            snackBarElement?.current?.displayToast(
              "Something went wrong retrieving analyses",
              "error",
              5000
            );
          })
          .finally(() => setIsLoading(false));

        //if projectId is specified, get the specific project from the backend (for the projectName)
        fetch(
          `${projectURL}?organization_id_list=-${
            Object.keys(organizationMemo.lookup).length > 1 ? 1 : 2
          }`,
          { method: "GET", headers: headers }
        ).then((projectResponse) => {
          if (projectResponse.ok) {
            return projectResponse.json().then(({ data: projects }) => {
              let project_lookup = {};

              projects.forEach((project) => {
                //creates a dictionary of the project ID and their names
                project_lookup[project.id] = project.name;
              });
              setProjectLookup(project_lookup);
              if (projectId) {
                const project = projects.find(
                  (eachProject) => eachProject.id == projectId
                );
                setProject(project);
              }
            });
          }
          //else
          errorHandler(projectResponse, snackBarElement);
        });
      } catch (e) {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Something went wrong retrieving analyses",
          "error",
          5000
        );
        setIsLoading(false);
      }
    }
    fetchData();
  }, [projectId, depotId, organizationMemo.lookup]);

  /**
   * populates the indexDb with all the simulation's Steps, and returns the current_page path string
   * @param {*} event
   * @param {Object} simulation the simulation row
   * @param {Number} simulation.id the id of the row's simulation
   * @returns {String|-1} the current_page value, or -1 if invalid
   */
  const populateIndexDb = (event, rowData) => {
    //TODO: add error messages!!!
    event.preventDefault();

    //set the relevant data, and go to the last editted page
    clearLocalDb(); //flush previous locally stored data

    const headers = { Authorization: `Token ${UseAuth("get")}` };

    return fetch(
      `${simulationURL}?simulation_id_list=${rowData.id}&all_info=T`,
      { method: "GET", headers: headers }
    ).then((response) => {
      if (response.ok) {
        return response.json().then(({ data: simulations }) => {
          const simData = simulations[0];
          //grab all data from backend
          const simSteps = simData?.steps;

          //if the selected simulation is of comparison type AND one of the simulations to be compared is incomplete OR deleted, exit the population function
          if (
            simSteps?.input?.simulation?.simulation_ids?.reduce(
              (isValid, sim_id) =>
                isValid || !data.find((sim) => sim.id == sim_id)?.completed,
              false
            )
          ) {
            const sim_id_list = data.map((i) => i.id);
            //find the incomplete sim, or the id of the sim, if deleted
            const incomplete_sim =
              data.find(
                (sim) =>
                  simSteps.input.simulation.simulation_ids.includes(sim.id) &&
                  !sim.completed
              ) ||
              simSteps.input.simulation.simulation_ids.find(
                (sim_id) => !sim_id_list.includes(sim_id)
              );
            console.log(
              "incomplete sim",
              incomplete_sim,
              "sim_ids",
              simSteps.input.simulation.simulation_ids
            );
            snackBarElement?.current?.displayToast(
              `Error: analysis [${
                incomplete_sim?.name || incomplete_sim
              }] is incomplete or deleted`,
              "error",
              5000
            );
            return -1;
          }

          //todo: should probably put this in a Dexie transaction
          //and store it on frontend
          localDbTableNames.forEach((key) => {
            if (simSteps[key] && Object.values(simSteps[key]).length) {
              if (key == "manualInput") {
                //most of the stored values for indexDb are strings with an auto-incrementing "id" value, with the exception of manualInput
                localDb[key].bulkAdd(
                  simSteps[key].map((manualData) => {
                    return {
                      blockId: manualData.blockId,
                      data: manualData,
                      input: simSteps.input[key],
                    };
                  })
                );
                //todo: consider making an alternate key fpr the
              } else {
                storeLocalData(key, {
                  data: simSteps[key],
                  input: simSteps.input[key],
                });
              }
            }
          });
          //add in simulation data on frontend, if it didn't already exist on backend
          ///this occurs if on gtfs-import/manual-input pages, as the fleetInput page does not store the simulation in the steps in the initial POST request, as it does not know the simulation ID
          storeLocalData("simulation", {
            data: {
              id: simData.id,
              depot_id: simData.depot_id,
              name: simData.name,
              completed: simData.completed,
              description: simData.description,
              analysis_type: simData.analysis_type, // used to persist the useContext(dataContext)'s AnalysisStepsView state between refreshes
              analysis_type_vers: simData.analysis_type_vers, //not actually used, but included for easier checking
              analysis_type_steps: simData.analysis_type_steps, // includes the version information of analysis_type
              locked: Boolean(!simData.self_locked && simData.locked_by),
              // simulation fields not used in frontend pages (other than this page):
              // created_at: simData.created_at,
              // fiveDayFile: simData.fiveDayFile,
              // outputFile: simData.outputFile,
              // steps: simData.steps,
              // project_id: simData.project_id,
              // depot_name: simData.depot_name,
            },
            // input_method used to persist the useContext(dataContext)'s AnalysisStepsView state between refreshes
            // simulation_ids used for same purpose
            input: { ...simSteps?.input?.simulation },
          });

          analysisStepsViewMemo.setState({
            analysis_type: simData.analysis_type,
            ...simSteps?.input?.simulation,
          });

          return fetch(`${projectURL}?organization_id_list=-1`, {
            method: "GET",
            headers: headers,
          }).then((projectResponse) => {
            if (projectResponse.ok)
              return projectResponse.json().then(({ data: projectList }) => {
                const project = projectList.find(
                  (eachProject) => eachProject.id == simData.project_id
                );
                storeLocalData("project", { data: project });

                setAnalysisCookies(
                  simData.analysis_type_vers,
                  simData.name,
                  project?.id,
                  simData.depot_name,
                  project?.currency_code,
                  project?.unit,
                  simData?.feeder_id
                );

                return simData?.current_page;
              });
            else {
              errorHandler(projectResponse, snackBarElement);
              clearLocalDb();
              return -1;
            }
          });
        });
      }
      // else, if get simulation data response wasn't ok
      errorHandler(
        response,
        snackBarElement,
        "Failed to retrieve analysis data"
      );
      clearLocalDb();
    });
  };
  /**
   * TODO: Consider making this a util
   * NOTE: This is made as a separate React Component to preserve the sorting of the materialtable when opening/closing the dialog box
   * @param {Object} props.rowData the material table's rowData
   * @returns {React Element} dropdown menu, for the material table
   */
  function RowActionsDropdown(props) {
    //anchor element, for positioning the dropdown
    const { rowData } = props;
    /** @type {[EventTarget & HTMLButtonElement]} */
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const isOpen = Boolean(anchorEl);
    const isCombinationAnalysis = rowData?.analysis_type == 2;

    const stepInfoSliced = stepInfo.slice(1, -1);
    //used to determine which "resume from this page" buttons to disable
    let pageIndex = stepInfoSliced.findIndex(({ route }) =>
      rowData.current_page.includes(route)
    );
    if (pageIndex == -1 && rowData.current_page.includes("summary"))
      //either one of the start pages, in which case, leave as negative 1, or its the final page, in which case, make it equal the length of the stepInfoSliced
      pageIndex = stepInfoSliced.length;

    /**
     * populates the index db, and then navigates to desired page
     * @param {*} event the mouse click event
     * @param {string} [route] the route to navigate to once the indexDb is done populating
     */
    async function handleClick(event, route) {
      try {
        setIsLoading(true);
        //populates the indexDb with simulation data, and returns the current_page
        const page = await populateIndexDb(event, rowData);
        if (page == -1) setIsLoading(false);
        else if (route) navigate(route);
        else navigate(page);
      } catch (e) {
        console.error(e);
        snackBarElement.current.displayToast(
          "Something went wrong getting the simulation details",
          "error"
        );
        setIsLoading(false);
      }
    }

    return (
      <>
        <Tooltip title="Open Actions">
          <Button
            variant="outlined"
            className="btn"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={
              isOpen ? (
                <ExpandLess sx={{ pointerEvents: "none" }} />
              ) : (
                <ExpandMore sx={{ pointerEvents: "none" }} />
              )
            }
          >
            {isCombinationAnalysis ? "View" : "Options"}
          </Button>
        </Tooltip>
        {isCombinationAnalysis ? (
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={() => setAnchorEl(null)}
          >
            {comparisonStepInfo.map(({ label, route }) => (
              <MenuItem
                key={`${label}_edit`}
                onClick={(e) => handleClick(e, route)}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={() => setAnchorEl(null)}
          >
            {pageIndex != -1 && (
              <MenuItem
                onMouseEnter={() => setIsEditOpen(true)}
                onMouseLeave={() => setIsEditOpen(false)}
                disabled={
                  !accessRights.analysis.update_analysis ||
                  !!rowData.key_metrics
                }
                sx={{
                  width: anchorEl?.offsetWidth
                    ? `${anchorEl.offsetWidth}px`
                    : undefined,
                }}
              >
                <ChevronLeft sx={{ pointerEvents: "none" }} />
                {!rowData.self_locked && rowData.locked_by ? "View" : "Edit"}
                <Menu
                  sx={{ pointerEvents: "none" }} //prevents the popover from covering the entire page
                  open={isEditOpen}
                  onClose={() => setIsEditOpen(false)}
                  transformOrigin={{ horizontal: "right", vertical: "center" }}
                  anchorEl={anchorEl}
                  disableAutoFocus
                  disableEnforceFocus
                >
                  <span style={{ pointerEvents: "auto" }}>
                    {stepInfoSliced.map(({ route, label }, index) => (
                      <MenuItem
                        key={`${label}_edit`}
                        onClick={(e) => handleClick(e, route)}
                        disabled={index > pageIndex}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </span>
                </Menu>
              </MenuItem>
            )}
            {rowData.completed ? (
              // sub-menu
              <MenuItem
                onClick={(e) =>
                  handleClick(e, stepInfo.findLast((i) => i).route)
                }
                disabled={isLoading}
              >
                View Summary
              </MenuItem>
            ) : (
              <MenuItem
                onClick={handleClick}
                disabled={
                  Boolean(!rowData.self_locked && rowData.locked_by) ||
                  !accessRights.analysis.update_analysis ||
                  isLoading
                }
                sx={{
                  width: anchorEl?.offsetWidth
                    ? `${anchorEl.offsetWidth}px`
                    : undefined,
                }}
              >
                Resume
              </MenuItem>
            )}
          </Menu>
        )}
      </>
    );
  }

  /**
   * Creates a copy of a row, and adds it to the table
   * @param {Object} newRow the new simulation row
   * @param {Number} originalId the ID of the simulation to be duplicated (based off duplicateId)
   * @param {String} newRow.name the new name for the duplicate simulation
   * @param {String} newRow.description the new description for the duplicate simulation
   * @return {Promise<Number>} 0 for success, 1 for failure
   */
  const handleDuplicateRow = (newRow) => {
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };
    //specifying the ID field in the JSON to be sent in backend POST request tells the backend
    /// that "this is a duplicate of the row with this ID", but with this new name/description
    newRow.id = duplicateId;
    if (!(newRow.id && newRow.name)) {
      snackBarElement.current.displayToast(
        "Duplicate Simulation ID or Name missing/invalid",
        "error"
      );
      return new Promise((resolve) => resolve(1));
    }

    const body = {
      simulations: [newRow],
      receiver_org_id: -2,
    };

    //create a copy of a simulation
    return fetch(cloneSimulationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then(({ data: res_sim_list }) => {
            const originalRow = data.find((i) => i.id == newRow.id);
            //add in the current ISO date
            const updated_at = new Date().toISOString();
            const created_at = updated_at;
            const addedRow = {
              ...originalRow,
              ...newRow,
              id: res_sim_list[0].id,
              updated_at,
              created_at,
              locked_by: null,
              self_locked: false,
              key_metrics: undefined,
              analysis_id: undefined,
              is_primary: undefined,
              absolute_year: undefined,
            };

            setData([...data, addedRow]);
            snackBarElement.current.displayToast("Duplicated Row");
            return 0;
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to duplicate analysis"
          );
          return 1;
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Something went wrong, please try again later",
          "error"
        );
        return 1;
      });
  };

  /**
   * closes the design case dialog
   */
  function handleDesignCaseDialogClose() {
    setIsDesignCaseDialogOpen(false);
    setTimeout(() => setDesignCase({}), [300]); //wait for the close dialog animation to finish before clearing data
  }

  /**
   * Opens the design case dialog box, to CRUD the design case
   * @param {Object} analysis the analysis that the design case is modifying
   */
  function handleDesignCaseDialogOpen(analysis) {
    setDesignCase(analysis);
    setIsDesignCaseDialogOpen(true);
  }

  /**
   * Creates, Updates, or Deletes the design case, depending on if it already existed or not
   * @param {import("react").FormEvent} event
   * @param {"POST"|"PATCH"}
   */
  function hanldeDesignCaseCreateUpdate(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());

    const method = designCase.key_metrics ? "PATCH" : "POST";

    const method_lookup = { POST: "add", PATCH: "update" };

    setIsLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    let body = {
      analysis_id: designCase.id,
      is_primary: formJSON.is_primary == "on",
    };
    if (formJSON.absolute_year) body.absolute_year = formJSON.absolute_year;

    fetch(designCaseURL, {
      method: method,
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          const originalSimulation = data.find(
            (sim) => sim.id == designCase.id
          );
          Object.entries(formJSON).forEach(
            ([key, value]) => (originalSimulation[key] = value)
          );
          originalSimulation["key_metrics"] = {}; //to keep repeat visits consistent, since we don't actually need the key_metrics for this
          setData([...data]);
          handleDesignCaseDialogClose();
        } else
          errorHandler(
            response,
            snackBarElement,
            `Failed to ${method_lookup[method]} design case`
          );
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "error",
          `Network Error: failed to ${method_lookup[method]} design case`,
          5000
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * Deletes the design case of the currently open dialog box
   */
  function handleDesignCaseDelete(event) {
    event.preventDefault();

    setIsLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = { analysis_id_list: [designCase.id] };

    fetch(designCaseURL, {
      method: "DELETE",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          const originalSimulation = data.find(
            (sim) => sim.id == designCase.id
          );
          //note: this doesn't (LOCALLY) clear out things like absolute_year, relative_year, and is_primary; since they'll disappear on page refresh/revisit, and so that you don't need to  add new values in the future
          originalSimulation["key_metrics"] = undefined; //to keep repeat visits consistent, since we don't actually need the key_metrics for this
          setData([...data]);
          handleDesignCaseDialogClose();
        } else
          errorHandler(
            response,
            snackBarElement,
            `Failed to remove design case`
          );
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "error",
          `Network Error: failed to remove design case`,
          5000
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * Determines whether to toggle analysis lock or display warning that analysis was locked by another user
   * @param {Object} row the simulation to lock/unlock
   */
  function handleLockToggleIntermediary(row) {
    if (!row.self_locked && row.locked_by && user_role == 1)
      setLockedAnalysis(row);
    else handleLockToggle(row);
  }

  /**
   * Locks/Unlocks analysis
   * @param {Object} row the simulation to lock/unlock
   */
  const handleLockToggle = (row) => {
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = { id: row.id };
    materialTableRef.current.setState((prev) => ({ ...prev, isLoading: true }));
    //create a copy of a simulation
    fetch(toggleLockSimulationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.ok) {
          const { data: localSim } = await getLocalData("simulation", "data");
          if (localSim && row.id == localSim.id) {
            //if the currently stored sim in frontend is the sim that was deleted, clear frontend
            clearLocalDb();

            analysisStepsViewMemo.setState({
              analysis_type: 0,
              input_method: 0,
            });
          }
          //doesn't really matter what the response is beyond the status, as it's just an empty object
          const originalRow = data.find(({ id }) => id == row.id);
          // originalRow.updated_at = new Date().toISOString(); // commented out, to prevent the analyses from jumping around
          if (originalRow.locked_by) {
            originalRow.locked_by = null;
            originalRow.self_locked = false;
          } else {
            originalRow.locked_by = true; //possibly temporary, since we don't know the user's ID
            originalRow.self_locked = true;
          }
          setData([...data]);
        } else
          errorHandler(
            response,
            snackBarElement,
            "Failed to toggle analysis lock"
          );
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network Error: Failed to toggle analysis lock",
          "error"
        );
      })
      .finally(() => {
        materialTableRef.current.setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  /**
   * updates simulations
   * @param {Object} newRow the new simulation row
   * @param {Object} oldRow the original simulation row
   * @return {Promise<(0|1)>} 0 for success, 1 for failure
   */
  function handleUpdate(newRow, oldRow) {
    if (
      oldRow.name == newRow.name &&
      oldRow.description == newRow.description
    ) {
      snackBarElement?.current?.displayToast(
        "Analysis data not altered from original.",
        "info"
      );
      return new Promise((resolve) => resolve(1));
    }
    const body = {
      ...oldRow,
      name: newRow.name,
      description: newRow.description,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${UseAuth("get")}`,
    };
    return fetch(simulationURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          const index = data.findIndex((x) => x.id == oldRow.id);
          data[index] = body;
          setData([...data]);
          snackBarElement?.current?.displayToast("Updated analysis");
          return 0;
        } else {
          errorHandler(response, snackBarElement, "Failed to update analysis");
          return 1;
        }
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(
          "Network Error: Failed to update analysis",
          "error"
        );
        return 1;
      });
  }

  /**
   * deletes simulations
   * @param {Number[]} simulation_id_list a list of the Ids of which simulations to delete
   * @return {Promise<(0|1)} 0 for success, 1 for failure
   */
  const handleDelete = (simulation_id_list) =>
    fetch(simulationURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify({ simulation_id_list }),
    })
      .then(async (response) => {
        if (response.ok) {
          const { data: localSim } = await getLocalData("simulation", "data");
          if (localSim && simulation_id_list.includes(localSim.id)) {
            //if the currently stored sim in frontend is the sim that was deleted, clear frontend
            clearLocalDb();

            analysisStepsViewMemo.setState({
              analysis_type: 0,
              input_method: 0,
            });
          }
          setData(
            data.filter((entry) => !simulation_id_list.includes(entry.id))
          );
          snackBarElement.current.displayToast(
            `Analys${simulation_id_list?.length > 1 ? "e" : "i"}s Deleted!`,
            "success",
            1500
          );
          return 0;
        } else {
          errorHandler(
            response,
            snackBarElement,
            `Failed to delete analys${
              simulation_id_list?.length > 1 ? "e" : "i"
            }s`
          );
          return 1;
        }
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(
          `Network Error: Failed to delete analys${
            simulation_id_list?.length > 1 ? "e" : "i"
          }s`,
          "error"
        );
        return 1;
      });

  function formatAndString(string) {
    let stringArr = string.split(" ");
    if (stringArr.includes("and")) {
      const andIndex = string.split(" ").indexOf("and");
      stringArr[andIndex] = "&";
    }

    return stringArr.join(" ");
  }

  /**
   * @type {import("@material-table/core").Column<never>[]}
   * formats the table*/
  const columns = [
    {
      title: "Organization",
      field: "organization_id",
      lookup: organizationMemo?.lookup || {},
      emptyValue: "My Organization",
      editable: "never",
    },
    {
      title: "Name",
      field: "name",
      validate: (rowData) =>
        rowData.name === "" ||
        !rowData.name ||
        !rowData.name.replace(/\s/g, "").length
          ? "Empty Value"
          : true,
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          id="name"
          name="name"
          label="Name"
          variant="standard"
          multiline
          required
          autoFocus
          error={!props?.value?.replace(/\s/g, "")?.length ? true : false}
          helperText={
            !props?.value?.replace(/\s/g, "")?.length ? "Empty Value" : ""
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Description",
      field: "description",
      initialEditValue: "",
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          id="description"
          name="description"
          label="Description"
          variant="standard"
          multiline
          value={props?.value ?? ""}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
    },
    {
      title: "Analysis Type",
      field: "analysis_type",
      lookup: TYPE_STRINGS.SIMULATION_TYPE,
      editable: "never",
    },
    {
      title: "Updated at",
      field: "updated_at",
      type: "date",
      defaultSort: "desc",
      editable: "never",
      intialEditValue: null,
      hidden: true,
    },
    {
      title: "Updated by",
      field: "updated_by",
      render: (rowData) =>
        `${rowData.updated_by_first_name} ${rowData.updated_by_last_name}`,
      customFilterAndSearch: (term, rowData) =>
        `${rowData.updated_by_first_name} ${rowData.updated_by_last_name}`
          .toString()
          .toUpperCase()
          .includes(term.toUpperCase()),
      editable: "never",
      hidden: true,
    },
    {
      title: "Created On",
      field: "created_at",
      type: "date",
      defaultSort: "desc",
      intialEditValue: null,
      editable: "never",
    },

    {
      title: "Created by",
      field: "created_by",
      render: (rowData) =>
        `${rowData.created_by_first_name} ${rowData.created_by_last_name}`,
      customFilterAndSearch: (term, rowData) =>
        `${rowData.created_by_first_name} ${rowData.created_by_last_name}`
          .toString()
          .toUpperCase()
          .includes(term.toUpperCase()),
      editable: "never",
      hidden: true,
    },
    {
      title: "Status",
      field: "completed",
      editable: "never",
      cellStyle: {
        minWidth: 180,
      },
      render: (rowData) =>
        rowData.current_page.includes("summary") ||
        rowData.current_page.includes("Compare") ? (
          <div style={{ fontWeight: 400, fontSize: 18 }}>Completed</div>
        ) : (
          <>
            <div style={{ fontWeight: 400, fontSize: 18 }}>In Progress - </div>
            <div style={{ fontWeight: 500, fontSize: 13.5 }}>
              {formatAndString(
                stepInfo
                  .concat(Object.values(assessmentStepZero))
                  .find(({ route }) => route.includes(rowData.current_page))
                  .label
              )}
            </div>
          </>
        ),
      customFilterAndSearch: (term, rowData) =>
        (rowData.current_page.includes("summary") ||
        rowData.current_page.includes("Compare")
          ? "Completed"
          : `In Progress - ${formatAndString(
              stepInfo
                .concat(Object.values(assessmentStepZero))
                .find(({ route }) => route.includes(rowData.current_page)).label
            )}`
        )
          .toUpperCase()
          .includes(term.toUpperCase()),
    },
    {
      title: "View Details",
      editable: "never",
      sorting: false,
      search: false,
      render: (rowData) => <RowActionsDropdown rowData={rowData} />,
    },
    {
      title: "Project",
      field: "project_id",
      lookup: projectLookup,
      //hides project column when projectId param is not provided
      //(i.e. when viewing all simulations)
      hidden: projectId?.length > 0,
      editable: "never",
    },
  ];

  return (
    <div>
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {(projectId && project?.name) ||
          (depotId && data?.[0]?.depot_name) ? (
            <>
              Current {projectId ? "Project" : "Depot"}:{" "}
              <span className="header-green">
                {projectId ? project.name : data?.[0]?.depot_name}
              </span>
            </>
          ) : (
            "Analyses"
          )}
        </Typography>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <MaterialTable
            title="Manage Analyses"
            columns={columns}
            data={dataFiltered}
            icons={Icons("Analysis")}
            localization={{
              body: {
                emptyDataSourceMessage: isLoading
                  ? "Loading..."
                  : "No Data Found",
              },
              toolbar: { searchPlaceholder: "Filter", searchTooltip: "Filter" },
            }}
            tableRef={materialTableRef}
            isLoading={isLoading}
            actions={[
              (rowData) => ({
                icon: rowData.key_metrics ? StarIcon : StarBorderIcon,
                tooltip: rowData.key_metrics
                  ? "Remove Design Case"
                  : "Use Analysis as Design Case",
                position: "row",
                hidden: !(
                  rowData.locked_by &&
                  rowData.completed &&
                  rowData.analysis_type == 1
                ),
                onClick: () => handleDesignCaseDialogOpen(rowData),
              }),
              (rowData) => ({
                icon: rowData.locked_by ? LockIcon : LockOpenIcon,
                tooltip: rowData.self_locked
                  ? "Unlock Analysis"
                  : rowData.locked_by
                  ? `Locked by ${rowData.updated_by_first_name} ${rowData.updated_by_last_name}`
                  : "Lock Analysis",
                position: "row",
                disabled:
                  (!rowData.self_locked &&
                    rowData.locked_by &&
                    user_role != 1) ||
                  !accessRights.analysis.create_analysis,
                hidden: !!rowData.key_metrics,
                onClick: () => handleLockToggleIntermediary(rowData),
              }),
              {
                icon: ContentCopyIcon,
                tooltip: "Duplicate Row",
                position: "row",
                onClick: (event, rowData) => {
                  if (rowData.analysis_type == 2) {
                    snackBarElement?.current?.displayToast(
                      "Cannot duplicate and move Comparison-type analyses",
                      "warning",
                      5000
                    );
                    return;
                  }
                  const materialTable = materialTableRef.current;
                  //set the ID of the row to be duplicated, for use in handleDuplicateRow()
                  setDuplicateId(rowData.id);
                  materialTable.dataManager.changeRowEditing();
                  materialTable.setState({
                    ...materialTable.dataManager.getRenderState(),
                    showAddRow: true,
                  });
                },
                hidden: !accessRights.analysis.create_analysis,
              },
            ]}
            components={{
              Toolbar: (props) => {
                //overwrites the "Add Analysis" Button to redirect to the "Fleet Input" page
                let AddComponent = props?.actions?.find(
                  (action) => action.tooltip == "Add"
                );
                if (AddComponent) {
                  AddComponent.onClick = () => {
                    navigate(
                      stepInfo[0].route +
                        (projectId ? `?projectId=${projectId}` : "")
                    );
                  };
                  AddComponent.hidden = !accessRights.analysis.create_analysis;
                }
                return <MTableToolbar {...props} />;
              },
            }}
            editable={{
              isEditHidden: (rowData) =>
                rowData.key_metrics ||
                (!rowData.self_locked && !!rowData.locked_by),
              isDeleteHidden: (rowData) => !!rowData.locked_by,
              onRowAdd:
                (accessRights.analysis.create_analysis || undefined) &&
                ((newData) =>
                  // When submitting a "dupicate row" (temporarily removed)
                  new Promise((resolve, reject) => {
                    handleDuplicateRow(newData).then((status) => {
                      if (status == 0) resolve();
                      else reject();
                    });
                  })),
              onRowUpdate:
                (accessRights.analysis.update_analysis || undefined) &&
                ((newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handleUpdate(newData, oldData).then((status) => {
                      if (status == 0) resolve();
                      else reject();
                    });
                  })),
              onRowDelete:
                (accessRights.analysis.delete_analysis || undefined) &&
                ((oldData) =>
                  new Promise((resolve, reject) => {
                    handleDelete([oldData.id]).then((status) => {
                      if (status == 0) resolve();
                      else reject();
                    });
                  })),
            }}
          />
        </Paper>
      </Container>
      {/* Desgin case input dialog */}
      <Dialog
        open={isDesignCaseDialogOpen}
        onClose={handleDesignCaseDialogClose}
        component="form"
        onSubmit={hanldeDesignCaseCreateUpdate}
        fullWidth
      >
        <DialogTitle>
          Designate Design Case for {designCase.depot_name}
          <br />
          <Typography variant="body1">Analysis: {designCase.name}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={6} alignContent="center">
              This analysis represents{" "}
              <TextField
                name="absolute_year"
                type="number"
                size="small"
                defaultValue={designCase.absolute_year}
                placeholder="20XX"
                sx={{ width: "4em" }}
                inputProps={{
                  min: 2024,
                  style: { textAlign: "center", padding: 0 },
                }}
              />
              <br />
              in the fleet's electrification plan.
            </Grid>
            <Grid item xs={6} display="flex" alignItems="start">
              <Checkbox
                name="is_primary"
                defaultChecked={designCase.is_primary}
              />
              <Typography variant="body2" py="9px">
                This analysis also represents the fleet's target for
                electrification at this depot.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDesignCaseDialogClose}>Cancel</Button>
          {designCase.key_metrics && (
            <LoadingButton loading={isLoading} onClick={handleDesignCaseDelete}>
              Remove Design Case
            </LoadingButton>
          )}
          <LoadingButton type="submit" loading={isLoading}>
            {designCase.key_metrics ? "Save Changes" : "Save"}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* If an admin user tries to unlock an analysis locked by another user, display warning */}
      <Dialog
        open={Boolean(lockedAnalysis?.locked_by)}
        onClose={() => setLockedAnalysis({})}
      >
        <DialogTitle textAlign="center">
          <LockIcon color="secondary" sx={{ fontSize: "50px" }} />
          <br />
          Notice: Admin Unlock Analysis
        </DialogTitle>
        <DialogContent>
          {lockedAnalysis.name} is locked by{" "}
          {lockedAnalysis.updated_by_first_name}{" "}
          {lockedAnalysis.updated_by_last_name}. Are you sure you want to unlock
          it?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLockedAnalysis({})}>Cancel</Button>
          <Button
            onClick={() => {
              handleLockToggle(lockedAnalysis);
              setLockedAnalysis({});
            }}
          >
            Unlock Analysis
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
