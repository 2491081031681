import {
  Box,
  Button,
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Fragment, useEffect, useRef, useState } from "react";
import "../../../Grid.css";
import { tariffStyleKeyLookup } from "../../../static/constants/stepInfo";
import { monthNames } from "../../assessmentPages/pages/financialAnalysis";
import {
  currencySymbol,
  unitMoneyWithoutRounding,
} from "../../secondary/unitConversions";
import { roundNumber, stringCapitalize, unitWrapper } from "../../utils";
//todo: look into combining this file with financial analysis' and detailedenergyInput's equivalents

const periodColors = [
  "#ffffff",
  "#33FF57",
  "#5733FF",
  "#FF33A6",
  "#33A6FF",
  "#A6FF33",
  "#FFD633",
  "#FF336E",
  "#33FFD6",
  "#D633FF",
];

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const StyledTableContainer = styled(TableContainer)({
  maxWidth: "850px",
  maxHeight: "300px",
  margin: "auto",
  marginTop: "20px",
});

const StyledTableCell = styled(TableCell)({
  fontSize: "14px",
});

const dialogTextFieldProps = {
  fullWidth: true,
  variant: "outlined",
  sx: { mt: 1, mx: 1 },
  size: "small",
  required: true,
};

const DetailedTariffInputs = ({
  initialData,
  depotLookup,
  depotId,
  disabled,
}) => {
  const [tariffStyle, setTariffStyle] = useState(
    initialData?.tariff_style || 3
  );
  const [depot, setDepot] = useState(depotId || initialData?.depot_id);

  /** @type {[{backgroundColor: String, period: Number, energy: Number, demand: Number}[]]} */
  const [periodData, setPeriodData] = useState([
    {
      backgroundColor: "#ffffff",
      period: 1,
      energy: 0.0,
      demand: 0.0,
    },
  ]);
  const [dragStart, setDragStart] = useState(null);
  const [selectedCells, setSelectedCells] = useState([]);
  // a matrix of periods
  const [dataMatrix, setDataMatrix] = useState(
    Array(12)
      .fill()
      .map(() => Array(24).fill(1))
  );

  const colorChangeTimeout = useRef(0);

  const monthLowercase = monthNames.map((month) => month.toLowerCase());
  const tariff_fields = [
    {
      field: "energy",
      title: (
        <>
          Energy Charges{" "}
          {unitWrapper(
            `(${currencySymbol(depotLookup.currency_code[depot])}/kWh)`
          )}
        </>
      ),
    },
    {
      field: "demand",
      title: (
        <>
          Demand Charges{" "}
          {unitWrapper(
            `(${currencySymbol(depotLookup.currency_code[depot])}/kW)`
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    function fetchData() {
      //calculate the detailed data
      // periodlookup is a hashmap of [tariff] values, to determine if a period has been encountered before
      let periodLookup = {};
      let newPeriodData = [];

      const dataMatrix = monthLowercase.map((month) => {
        return Array(24)
          .fill()
          .map((_v, hour) => {
            const energy = initialData.tariffs.energy[month][hour];
            const demand = initialData.tariffs.demand[month][hour];
            const cellKey = `${energy}_${demand}`;
            if (!periodLookup[cellKey]) {
              //if this is the first time the energy/demand combo has been encountered, create new period
              periodLookup[cellKey] = newPeriodData.length + 1; // add 1, to avoid the !0 lookup in conditional

              newPeriodData.push({
                backgroundColor:
                  periodColors[newPeriodData.length % periodColors.length],
                period: newPeriodData.length + 1,
                energy: unitMoneyWithoutRounding(
                  energy,
                  depotLookup.currency_code[initialData.depot_id]
                ),
                demand: unitMoneyWithoutRounding(
                  demand,
                  depotLookup.currency_code[initialData.depot_id]
                ),
              });
            }
            return periodLookup[cellKey];
          });
      });

      //set the detailed data
      setPeriodData(newPeriodData);
      setDataMatrix(dataMatrix);
    }
    //potentially needs to run every initialDataChange
    if (initialData) fetchData();
  }, []);

  const handleAddRow = () => {
    const newPeriodValue = Number(periodData[periodData.length - 1].period);
    setPeriodData([
      ...periodData,
      {
        backgroundColor: periodColors[newPeriodValue % periodColors.length],
        period: newPeriodValue + 1,
        energy: 0.0,
        demand: 0.0,
      },
    ]);
  };

  const handleDeletePeriod = (deletedPeriod) => {
    setDataMatrix(
      dataMatrix.map((dataArray) =>
        dataArray.map((period) => (period == deletedPeriod ? 1 : period))
      )
    );

    const newData = periodData.filter((row) => row.period !== deletedPeriod);
    setPeriodData(newData);
  };

  const handleInputChange = (index, field, value) => {
    const newData = [...periodData];
    newData[index][field] = +value;
    if (value === "") newData[index][field] = value;
    setPeriodData(newData);
  };

  const hours = Array.from({ length: 24 }, (_, index) => index);

  const handleCellClick = (month, hour) => {
    if (dragStart) {
      return;
    }

    const clickedCell = { month: month, hour: hour };

    if (
      selectedCells.length === 1 &&
      selectedCells[0].month === clickedCell.month &&
      selectedCells[0].hour === clickedCell.hour
    ) {
      setSelectedCells([]);
    } else {
      setSelectedCells([clickedCell]);
    }
  };

  const handleDragStart = (month, hour) => {
    setDragStart({ month, hour });
  };

  const handleDragOver = (month, hour) => {
    if (dragStart) {
      const startMonth = dragStart.month;
      const startHour = dragStart.hour;

      const minMonth = Math.min(month, startMonth);
      const maxMonth = Math.max(month, startMonth);
      const minHour = Math.min(hour, startHour);
      const maxHour = Math.max(hour, startHour);

      const newSelection = [];

      for (let m = minMonth; m <= maxMonth; m++) {
        for (let h = minHour; h <= maxHour; h++) {
          newSelection.push({ month: m, hour: h });
        }
      }
      //keeping the selected cells in their own array seems a little innefficient
      // you could accomplish the same by merely keeping track of start & end indices
      setSelectedCells(newSelection);
    }
  };

  const handleDragEnd = () => {
    setDragStart(null);
  };

  const handleSetRowValue = (index) => {
    const selectedRow = periodData[index];
    const selectedValue = selectedRow ? selectedRow.period : "1";

    selectedCells.forEach((cell) => {
      dataMatrix[cell.month][cell.hour] = selectedValue;
    });

    setDataMatrix(JSON.parse(JSON.stringify(dataMatrix)));
  };

  const yearlyTariffInput = () => (
    <Grid justifyContent="center" container mt={2} spacing={2}>
      {tariff_fields.map(({ field, title }) => (
        <Fragment key={field}>
          <Grid item xs={12} sm={5}>
            <Typography variant="body1" gutterBottom align="left">
              Yearly {title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              required
              name={field}
              defaultValue={roundNumber(
                unitMoneyWithoutRounding(
                  initialData?.tariffs?.[field]?.january?.[0] || 0,
                  depotLookup.currency_code[depot]
                ),
                4
              )}
              variant="outlined"
              size="small"
              type="number"
              InputProps={{
                inputProps: { min: 0, step: 0.0001 },
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol(depotLookup.currency_code[depot])}
                  </InputAdornment>
                ),
              }}
              disabled={disabled}
            />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );

  /* Monthly Tariffs Table */
  const monthlyTariffInput = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Months</TableCell>
          {tariff_fields.map(({ field, title }) => (
            <TableCell key={`${field}_title`}>{title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {monthLowercase.map((month, monthIndex) => (
          <TableRow key={month}>
            <TableCell>{monthNames[monthIndex]}</TableCell>
            {tariff_fields.map(({ field }) => (
              <TableCell key={`${field}_${monthIndex}`}>
                <TextField
                  required
                  defaultValue={roundNumber(
                    unitMoneyWithoutRounding(
                      initialData?.tariffs?.[field]?.[month]?.[0] || 0,
                      depotLookup.currency_code[depot]
                    ),
                    4
                  )}
                  name={`${field}_${monthIndex}`}
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0, step: 0.0001 },
                    style: { height: "2rem" },
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol(depotLookup.currency_code[depot])}
                      </InputAdornment>
                    ),
                  }}
                  disabled={disabled}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const detailedTariffInput = () => (
    <Wrapper>
      {/* The formatted data that is passed up to parent component on submission, hidden from user */}
      <input
        name="detailedData"
        value={JSON.stringify({ periodData, dataMatrix })}
        readOnly
        hidden
      />
      <div>
        {/* Dynamic Table */}
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleAddRow}
                    disabled={disabled}
                  >
                    Add Period
                  </Button>
                </StyledTableCell>
                <StyledTableCell>Period</StyledTableCell>
                {tariff_fields.map(({ field, title }) => (
                  <StyledTableCell key={`${field}_title`}>
                    {title}
                  </StyledTableCell>
                ))}
                {!disabled && <StyledTableCell>Actions</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {periodData.map((row, index) => (
                <TableRow key={`period_${row.period}`}>
                  <StyledTableCell>
                    <input
                      type="color"
                      value={row.backgroundColor}
                      onChange={(e) => {
                        //uses a timeoutReference to avoid too many (hundreds per second) re-renders
                        row.backgroundColor = e.target.value;
                        clearTimeout(colorChangeTimeout.current);
                        colorChangeTimeout.current = setTimeout(() => {
                          setPeriodData(periodData.map((i) => i));
                        }, [100]);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.period}</StyledTableCell>
                  {tariff_fields.map(({ field }) => (
                    <StyledTableCell key={`${field}_${row.period}`}>
                      <TextField
                        required
                        variant="outlined"
                        size="small"
                        type="number"
                        value={
                          //currency conversion occurs in useEffect, to avoid having value change if depot changes
                          !isNaN(row[field]) && row[field] !== ""
                            ? roundNumber(row[field], 4)
                            : ""
                        }
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }
                        InputProps={{
                          inputProps: { min: 0.0, step: 0.0001 },
                          style: { width: "140px", height: "30px" },
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol(depotLookup.currency_code[depot])}
                            </InputAdornment>
                          ),
                        }}
                        disabled={disabled}
                      />
                    </StyledTableCell>
                  ))}
                  {!disabled && (
                    <StyledTableCell>
                      {row.period != 1 && (
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeletePeriod(row.period)}
                          >
                            Delete
                          </Button>
                          &nbsp;
                        </>
                      )}
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleSetRowValue(index)}
                      >
                        Set Selected
                      </Button>
                    </StyledTableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <br />
        {/* new grid */}
        <table
          style={{ borderCollapse: "collapse" }}
          onMouseLeave={handleDragEnd}
        >
          <tbody>
            <tr>
              <th className="header-cell" />
              {hours.map((hour) => (
                <th key={`${hour}:00_header`} className="header-cell">
                  {hour}:00
                </th>
              ))}
            </tr>
            {dataMatrix.map((rowData, row) => (
              <tr key={`${monthLowercase[row]}_row`}>
                <th className="header-cell">{monthNames[row]}</th>
                {rowData.map((cellPeriod, col) => (
                  <td
                    key={`row${row}_col${col}`}
                    className="data-cell"
                    onClick={() => handleCellClick(row, col)}
                    onMouseDown={() => handleDragStart(row, col)}
                    onMouseEnter={() => handleDragOver(row, col)}
                    onMouseUp={handleDragEnd}
                    style={{
                      backgroundColor: periodData.find(
                        (period) => period.period == dataMatrix[row][col]
                      )?.backgroundColor,
                      // add some highlights to the selected cells
                      boxShadow: selectedCells.some(
                        ({ month, hour }) => month == row && hour == col
                      )
                        ? "inset 0 0 0 1000px rgba(63,143,227,.4)"
                        : "none",
                      borderColor: selectedCells.some(
                        ({ month, hour }) => month == row && hour == col
                      )
                        ? "rgba(63,143,227)"
                        : "",
                    }}
                  >
                    {cellPeriod}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );

  return (
    <>
      <DialogTitle display="flex" justifyContent="space-between">
        {disabled ? "Preview" : "Modify"} Tariff
        <Stack direction="row" spacing={1}>
          {[1, 2, 3].map((key) => (
            <Chip
              key={`${tariffStyleKeyLookup[key]}_chip`}
              label={stringCapitalize(tariffStyleKeyLookup[key])}
              variant={tariffStyle == key ? "filled" : "outlined"}
              onClick={() => setTariffStyle(key)}
            />
          ))}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {/* selected chip, depot_id, name and utility name fields */}
        <Box display="flex" justifyContent="space-evenly">
          <input name="id" value={initialData?.id || ""} hidden readOnly />
          <input name="tariff_style" value={tariffStyle} hidden readOnly />
          <input
            name="depot_id"
            value={depotId || initialData?.depot_id || ""}
            disabled={!(depotId || initialData?.depot_id)}
            hidden
            readOnly
          />
          <TextField
            {...dialogTextFieldProps}
            label="Depot"
            name="depot_id"
            disabled={!!(depotId || initialData?.depot_id || disabled)}
            value={depot || ""}
            onChange={(e) => setDepot(e.target.value)}
            select
          >
            {Object.entries(depotLookup.name).map(([id, name]) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            {...dialogTextFieldProps}
            name="utility_name"
            defaultValue={initialData?.utility_name || ""}
            label="Utility Name"
            disabled={disabled}
          />
          <TextField
            {...dialogTextFieldProps}
            name="name"
            defaultValue={initialData?.name || ""}
            label="Tariff Name"
            disabled={disabled}
          />
        </Box>
        {tariffStyle == 1
          ? yearlyTariffInput()
          : tariffStyle == 2
          ? monthlyTariffInput()
          : tariffStyle == 3
          ? detailedTariffInput()
          : "Unhandled Chip View"}
      </DialogContent>
    </>
  );
};

export default DetailedTariffInputs;
