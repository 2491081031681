import DirectionsBus from "@mui/icons-material/DirectionsBus";
import ElectricCar from "@mui/icons-material/ElectricCar";
import EvStation from "@mui/icons-material/EvStation";
import OfflineBolt from "@mui/icons-material/OfflineBolt";
import { Badge, Stack, Tooltip } from "@mui/material";

/**
 * displays a vehicle icon, with a badge indicating number of vehicles, and a tooltip with more info
 * @param {{depot: Number, resource_id: Number, count: Number}[]} props/resourceList - a list of resources (can be for all depots)
 * @param {Number} props.depot_id the ID of the depot containing the resources
 * @returns {JSX Object}
 */
export default function DepotResourceIcon(props) {
  const { vehicleList, tariffList, depot_id, ...rest } = props;

  // count resources associated with depot
  const vehicleCount = vehicleList.filter(
    (vehicle) => vehicle.depot_id == depot_id
  ).length;

  const tariffCount = tariffList.filter(
    (tariff) => tariff.depot_id == depot_id
  ).length;

  return (
    <Stack
      display="flex"
      justifyContent="center"
      spacing={2}
      direction="row"
      {...rest}
    >
      <Tooltip
        title={`${vehicleCount} Vehicle Model${vehicleCount == 1 ? "" : "s"}`}
      >
        <Badge badgeContent={vehicleCount} max={999}>
          <DirectionsBus />
        </Badge>
      </Tooltip>
      <Tooltip
        title={`${tariffCount} Tariff Model${tariffCount == 1 ? "" : "s"}`}
      >
        <Badge badgeContent={tariffCount} max={999}>
          <OfflineBolt />
        </Badge>
      </Tooltip>
    </Stack>
  );
}

/**
 * display icons for primary design case
 * @param {import("@mui/material").StackProps<any, {depot_id: number, designCaseLookups: Object}>} props
 */
export function DesignCaseIcons(props) {
  const { depot_id, designCaseLookups, ...rest } = props;
  if (designCaseLookups.primary[depot_id]) {
    const { num_chargers, ev_fleet_size } = designCaseLookups.primary[depot_id];
    return (
      <Stack
        display="flex"
        justifyContent="left"
        spacing={2}
        direction="row"
        {...rest}
      >
        <Tooltip
          title={`${num_chargers} Charger${num_chargers == 1 ? "" : "s"}`}
        >
          <Badge badgeContent={num_chargers} max={999}>
            <EvStation />
          </Badge>
        </Tooltip>
        <Tooltip
          title={`${ev_fleet_size} Electric Vehicle${
            ev_fleet_size == 1 ? "" : "s"
          }`}
        >
          <Badge badgeContent={ev_fleet_size} max={999}>
            <ElectricCar />
          </Badge>
        </Tooltip>
        {/* <Badge
          badgeContent={
            designCaseLookups.primary[depot_id]?.max_full_power
          }
          max={999}
        >
          <Bolt />
        </Badge> */}
      </Stack>
    );
  } else return "None Found";
}
