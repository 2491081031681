import AcUnit from "@mui/icons-material/AcUnit";
import AirportShuttle from "@mui/icons-material/AirportShuttle";
import ElectricRickshawIcon from "@mui/icons-material/ElectricRickshaw";
import EvStation from "@mui/icons-material/EvStation";
import LocalFlorist from "@mui/icons-material/LocalFlorist";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Star from "@mui/icons-material/Star";
import WbSunny from "@mui/icons-material/WbSunny";
import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { latLngBounds } from "leaflet";
import { useContext, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip as TooltipMap,
} from "react-leaflet";
import { Link } from "react-router-dom";
import { DataContext } from "../../../contexts/dataContext";
import FallIcon from "../../../static/images/fall-icon.png";
import { MFM_to_AMPM, unitWrapper } from "../../utils";
import DepotResourceIcon from "../depotResourceIcon";

/**
 *
 * @param {Object} props
 * @param {{primary: Object, all: Object}} props.designCaseLookups
 * @param {{depot_id: Simulation}} [props.simulationLookup]
 * @param {Object[]} props.coordinates list of pins
 * @param {number} props.coordinates[].latitude
 * @param {number} props.coordinates[].longitude
 * @param {string} props.coordinates[].name for tooltip title
 * @returns
 */
function DepotMap({
  projectLookup,
  simulationLookup,
  designCaseLookups,
  coordinates, //coordinates of markers for map
  vehicleList,
  tariffList,
}) {
  const { organizationMemo } = useContext(DataContext);
  const coordinatesFiltered = useMemo(
    () =>
      coordinates.filter(
        (coordinate) =>
          !organizationMemo?.id ||
          coordinate.organization_id == organizationMemo.id
      ),
    [coordinates, organizationMemo]
  );

  const center = coordinatesFiltered
    .reduce(
      //calculates the average lat/long value, for the center of the map
      ([sum_latitude, sum_longitude], { latitude, longitude }) => [
        sum_latitude + latitude,
        sum_longitude + longitude,
      ],
      [0, 0]
    )
    .map((value) => value / coordinatesFiltered.length);

  let bounds = latLngBounds(); //init boundaries, so that map zooms to fit the coordinates
  coordinatesFiltered.forEach(
    ({ latitude, longitude }) => bounds.extend([latitude, longitude]) //calculate the north-eastern-most and south-western most boundaries
  );
  if (!Object.keys(bounds).length) {
    //if there were no points to focus/zoom in on, then default to the world map
    bounds.extend([-85, -175]);
    bounds.extend([85, 175]);
  }

  const pins = coordinatesFiltered.map((point, index) => {
    // Define the fields to display in the popup
    const fieldsToDisplayBig = ["address1", "address2"];
    const fieldsToDisplaySmall = ["city", "state", "country"];

    const AddressComponent = () => (
      <>
        {/* the address */}
        {fieldsToDisplayBig.map((key) => (
          <div style={{ fontSize: "1.25em" }} key={key}>
            {point?.[key]?.trim?.() || point?.[key]}
          </div>
        ))}
        {fieldsToDisplaySmall.map((key) => (
          <div key={key}>{point?.[key]?.trim?.() || point?.[key]}</div>
        ))}
        <div>{point?.feeder_id && `Feeder: ${point.feeder_id.trim()}`}</div>
      </>
    );

    //only used on depots with a primary design case
    const designCase = designCaseLookups.primary[point.id];
    const maxHourlyPower = designCase && designCase.max_full_power;

    /** @type {import("chart.js").ChartOptions} */
    const barChartOptions = {
      responsive: true,
      scales: {
        x: {
          grid: { offset: false },
          ticks: {
            callback: (val) =>
              val == 12
                ? MFM_to_AMPM(val * 60)
                : val % 6 == 0 && val != 0
                ? ""
                : null,
          },
        },
        y: {
          title: { display: true, text: "kW" },
          ticks: { display: false },
          grid: { display: false },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          position: "nearest",
          callbacks: {
            title: (value) => MFM_to_AMPM(value[0].label * 60),
            label: (value) => `${Math.round(value.raw)} kW`,
          },
        },
        annotation: {
          annotations: {
            line1: {
              type: "line",
              yMax: maxHourlyPower,
              yMin: maxHourlyPower,
              borderColor: "khaki",
            },
          },
        },
      },
    };

    return (
      <Marker key={index} position={[point.latitude, point.longitude]}>
        <TooltipMap>
          <div style={{ textAlign: "left" }}>
            <big>
              <b>{point.name}</b>
              <br />
              Fleet: {projectLookup?.[point?.project_id]?.name}
            </big>
          </div>
        </TooltipMap>
        <Popup minWidth={200} maxWidth={1000}>
          {/* Display the name */}
          <Typography
            fontSize="1.5em"
            display="flex"
            justifyContent="space-between"
            style={{ marginBottom: 0 }}
          >
            <span>
              <b>{point.name}&nbsp;&nbsp;&nbsp;</b>
              <br />
              <Link
                to={`/simulations?depotId=${point.id}`}
                style={{ fontSize: "0.75em" }}
              >
                Go to analyses
              </Link>
            </span>
            <span style={{ textAlign: "right" }}>
              {projectLookup?.[point?.project_id]?.name}
              <br />
              {designCase
                ? ""
                : unitWrapper(
                    simulationLookup?.[point.id]
                      ? "No Primary Design Case Set"
                      : "No Analyses Exist",
                    {
                      style: { color: "orange", fontSize: "0.75em" },
                    }
                  )}
            </span>
          </Typography>
          <Divider sx={{ borderColor: "#64C8FF", mb: 1 }} />
          {designCase ? (
            <>
              <Grid container>
                <Grid item xs={4}>
                  <AddressComponent />
                </Grid>
                <Grid item xs={4} px={1}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pl: "8px",
                    }}
                  >
                    <span>
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.ev_fleet_size}
                      </span>
                      <br />
                      Vehicle
                      {designCase.ev_fleet_size == 1 ? "" : "s"}
                      <br />
                      <br />
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.unique_models.length}
                      </span>
                      <br />
                      Vehicle Type
                      {designCase.unique_models.length == 1 ? "" : "s"}
                    </span>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <ElectricRickshawIcon
                        className="rotate-icon-4"
                        color="disabled"
                        sx={{
                          fontSize: "3em",
                          mr: 2,
                          mb: "-0.25em",
                          mt: "0.25em",
                        }}
                      />
                      <AirportShuttle
                        className="rotate-icon-4"
                        color="disabled"
                        sx={{ fontSize: "3em", ml: "-2em", mb: "-0.25em" }}
                      />
                      <LocalShippingIcon
                        className="rotate-icon-4"
                        color="disabled"
                        sx={{ fontSize: "3em", mr: 1.5, mb: "0.25em" }}
                      />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={4} px={1}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                      overflow: "hidden",
                      pl: "8px",
                      mr: -1,
                    }}
                  >
                    <span>
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.num_chargers}
                      </span>
                      <br />
                      Charger{designCase.num_chargers == 1 ? "" : "s"}
                      <br />
                      <br />
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.charger_model.rating}&nbsp;
                        {unitWrapper("kW")}
                      </span>
                      <br />
                      Rating
                    </span>
                    <EvStation
                      className="rotate-icon-4"
                      color="disabled"
                      sx={{ fontSize: "8em", py: "0.0625em" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
              <br />
              <Stack direction="row" spacing={1}>
                <Paper elevation={3} sx={{ minWidth: "25em", pr: "1em" }}>
                  <Box
                    display="flex"
                    textAlign="center"
                    justifyContent="center"
                  >
                    Hourly Load Profile for Peak Day
                  </Box>
                  <Bar
                    data={{
                      labels: Array(24)
                        .fill()
                        .map((_v, i) => i),
                      datasets: [{ data: designCase.full_power_hourly }],
                    }}
                    options={barChartOptions}
                  />
                </Paper>
                <Paper elevation={3} component={Grid} container minWidth="25em">
                  <Grid item xs={12} sx={{ maxHeight: "1em", pl: "0.5em" }}>
                    1-Minute Peak Day
                  </Grid>
                  <Grid item xs={6} textAlign="center" alignContent="center">
                    <Typography style={{ margin: 0, fontSize: "1.5em" }}>
                      {Math.round(designCase.max_full_power).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kW")}
                    </Typography>
                    <Typography variant="body2" style={{ margin: 0 }}>
                      Power
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="center" alignContent="center">
                    <Typography style={{ margin: 0, fontSize: "1.5em" }}>
                      {Math.round(
                        designCase.full_power_hourly.reduce(
                          (sum, curr) => sum + curr,
                          0
                        )
                      ).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kWh")}
                    </Typography>
                    <Typography variant="body2" style={{ margin: 0 }}>
                      Energy Day
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ borderColor: "#64C8FF", mb: 0.1 }} />
                  </Grid>
                  <Grid item xs={12} sx={{ maxHeight: "1em", pl: "0.5em" }}>
                    Seasonal 15-Minute Peak and Average
                  </Grid>
                  {[
                    <AcUnit />,
                    <LocalFlorist />,
                    <WbSunny />,
                    <img src={FallIcon} height="24px" width="24px" />,
                  ].map((icon, index) => (
                    <Grid
                      item
                      xs={3}
                      key={`season_${index}`}
                      textAlign="center"
                    >
                      {icon}
                      <br />
                      {Math.round(
                        designCase.seasonal_load_profile[index].peak_power
                      ).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kW")}
                      <br />
                      {Math.round(
                        designCase.seasonal_load_profile[index].average_day
                      ).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kWh")}
                    </Grid>
                  ))}
                </Paper>
              </Stack>
            </>
          ) : (
            <>
              <Grid container alignItems="stretch" spacing={1}>
                <Grid item xs={simulationLookup?.[point.id] ? 5 : 9}>
                  <AddressComponent />
                </Grid>
                <Grid
                  item
                  xs={simulationLookup?.[point.id] ? 5 : 1}
                  component={simulationLookup?.[point.id] ? Paper : undefined}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ px: 1, mt: 1 }}
                >
                  {simulationLookup?.[point.id] ? (
                    <span>
                      <span style={{ color: "#007FFF", fontSize: "2em" }}>
                        {simulationLookup[point.id].num_complete}
                      </span>
                      <span style={{ fontSize: "1.5em" }}>&nbsp;of&nbsp;</span>
                      <span style={{ color: "#007FFF", fontSize: "2em" }}>
                        {simulationLookup[point.id].num_analyses}
                      </span>
                      <br />
                      Analyses&nbsp;completed
                    </span>
                  ) : undefined}
                </Grid>
                <Grid item xs={2}>
                  <Paper
                    sx={{
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <DepotResourceIcon
                      vehicleList={vehicleList}
                      tariffList={tariffList}
                      depot_id={point.id}
                      alignItems="center"
                      direction="column"
                      spacing={1}
                      py={1}
                    />
                  </Paper>
                </Grid>
              </Grid>
              <br />a Design Case can be set by selecting{" "}
              <Star style={{ verticalAlign: "middle" }} /> next to a<br />
              completed and locked analysis
            </>
          )}
        </Popup>
      </Marker>
    );
  });

  return (
    //makes the map
    <MapContainer center={center} bounds={bounds}>
      <TileLayer
        // attribution text
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {pins}
    </MapContainer>
  );
}

export default DepotMap;
