import MaterialTable from "@material-table/core";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  unitPoundMapAbbr,
  unitVolumeMap,
} from "../../static/constants/systems_of_measurement";
import {
  currencyDisplay,
  getUnits,
  unitGallons,
  unitPound,
} from "../secondary/unitConversions";
import { Icons, exportCSV, jsxToString, numberUS, roundNumber } from "../utils";

const columnComp = (props) => {
  return (
    <>
      {props.simulationTitle}
      <br />
      Electric Vehicle
      <IconButton
        size="small"
        onClick={() => {
          props.setSimulationInfoOpen(true);
          props.setSimulationInfoIndex(props.index);
        }}
        tabIndex={-1}
      >
        <InfoIcon />
      </IconButton>
    </>
  );
};

function ToggleFormComponent(props) {
  /**
   *
   * @param {import("react").ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e
   */
  function handleFormChange(e) {
    e.preventDefault();
    const [simName, fuel] = e.target.value.split(" - ");
    props.setSelectedSim(simName);
    props.setSelectedFuel(fuel);
  }
  return (
    <TextField
      fullWidth
      variant="outlined"
      sx={{ fontSize: "15px", fontWeight: 600 }}
      onChange={handleFormChange}
      label="Fueled Vehicle"
      InputLabelProps={{ sx: { fontSize: "16px", fontWeight: 700 } }}
      select
      SelectProps={{ sx: { fontSize: "15px", fontWeight: 600 } }}
      value={`${props.selectedSim} - ${props.selectedFuel}`}
    >
      {props.simFuelOptions.map((sim) => (
        <MenuItem key={sim} value={sim}>
          {sim}
        </MenuItem>
      ))}
    </TextField>
  );
}

const customRowStyle = {
  big: { fontSize: "18px", fontWeight: 600 }, //big and bold
  small: { fontWeight: 600 }, //small and bold
  undefined: { fontSize: "15px", fontWeight: 400 }, //standard
};

/**
 *
 * @param {Object} props
 * @param {Object} props.tcoData
 * @param {Object} props.fleetSizes For the managed/unmanaged transformer Capacity
 * @param {String} props.className additional classes to attach to the Paper compoennt
 */
export default function TCOTable(props) {
  //parent states
  const simulationNames = props.allProjectsData?.map((el) => el.name);
  const tableData = props.tcoData;
  const fleetSizes = props.fleetSizes;
  const { projName, simName } = props;

  //local states
  const [isPerVehicle, setIsPerVehicle] = useState(false); // can be used to divide all fields by a number
  const [selectedSim, setSelectedSim] = useState(simulationNames[0]); // can be used to divide all fields by a number
  const [simulationInfoOpen, setSimulationInfoOpen] = useState(false);
  const [simulationInfoIndex, setSimulationInfoIndex] = useState(0);
  /** @type {["ICE"|"GAS"|"PROP"|"CNG"|undefined]} */
  const [selectedFuel, setSelectedFuel] = useState("");

  const fuel = selectedFuel.toLowerCase();
  const simFuelOptions = tableData.reduce(
    (optionsArr, analysis_tco, simIndex) => [
      ...optionsArr,
      ...Object.keys(analysis_tco)
        .filter((fuel_type) => fuel_type in TYPE_STRINGS.FUEL_TYPE)
        .map((fuel_type) => `${simulationNames[simIndex]} - ${fuel_type}`),
    ],
    []
  );
  const units = getUnits();

  //sets the initial fuel type
  useEffect(() => {
    function fetchData() {
      setSelectedFuel(
        Object.keys(tableData[simulationNames.indexOf(selectedSim)]).find(
          (i) => i in TYPE_STRINGS.FUEL_TYPE
        )
      );
    }

    fetchData();
  }, []);

  const tcoLife = tableData[0].BEV.tco_list.length - 1;
  const sim = props.allProjectsData
    ? props.allProjectsData[simulationInfoIndex]
    : null;
  //determines the key lookup path for the evAssessmnet flags
  const isV2s25d =
    +sim?.analysis_type_steps?.fleet_and_charger_sizing?.depot_energy_analysis?.split(
      "."
    )?.[0] >= 2;
  const columns = [
    { title: "Category", field: "category" },
    { title: "Subcategory", field: "subCategory" },
    {
      title: (
        <ToggleFormComponent
          selectedFuel={selectedFuel}
          setSelectedFuel={setSelectedFuel}
          selectedSim={selectedSim}
          setSelectedSim={setSelectedSim}
          simFuelOptions={simFuelOptions}
        />
      ),
      field: `${fuel}${simulationNames.indexOf(selectedSim)}`,
    },
  ];

  const column2 = tableData.reduce((acc, current, index) => {
    const obj = {
      title: columnComp({
        simulationTitle: simulationNames[index],
        simulationInfoOpen,
        setSimulationInfoOpen,
        setSimulationInfoIndex,
        index: index,
      }),
      field: `ev${index}`,
    };
    acc.splice(acc.length - 1, 0, obj);
    return acc;
  }, columns);

  function getValueFromNestedObject(
    obj,
    renderValue,
    fallback,
    denominator,
    renderFunction = (i) => i
  ) {
    const keys = renderValue.split("[").map((key) => key.replace("]", ""));
    const value = keys.reduce((nestedObj, nestedKey) => {
      if (typeof nestedObj === "undefined") {
        return undefined;
      }
      return nestedObj[nestedKey];
    }, obj);

    const deno = isPerVehicle ? denominator : 1;

    return isNaN(value) ? fallback : renderFunction(value / deno);
  }

  function generateDataObj(rowName) {
    let dataArray;
    //todo: replace this with an object
    switch (rowName) {
      case "Cost of Ownership":
        dataArray = [
          {
            name: "Total Cost of Ownership",
            ev: {
              renderValue: "totalTCO",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "totalTCO",
              fallback: "N/A",
            },
            spanWrapper: "big",
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: "Net Present Value",
            ev: {
              renderValue: "npv",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "npv",
              fallback: "N/A",
            },
            renderFunction: (value) => currencyDisplay(value),
          },
        ];
        break;
      case "Capital Expenses":
        dataArray = [
          {
            name: (
              <>
                Initial Investment
                <br />
                (with Incentives)
              </>
            ),
            ev: {
              renderValue: "initial_capital",
              fallback: "-",
            },
            [fuel]: {
              renderValue: "initial_capital",
              fallback: "-",
            },
            spanWrapper: "big",
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: (
              <>
                Initial Investment
                <br />
                (without Incentive)
              </>
            ),
            ev: {
              renderValue: "initial_capital_minus_incentives",
              fallback: "-",
            },
            [fuel]: {
              renderValue: "initial_capital",
              fallback: "-",
            },
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: "Total Incentive",
            ev: {
              renderValue: "incentives",
              fallback: "-",
            },
            [fuel]: null,
            spanWrapper: "small",
            renderFunction: (value) => `(${currencyDisplay(value)})`,
          },
          {
            name: "Vehicle Incentive",
            ev: {
              renderValue: "vehicle_incentives",
              fallback: "-",
            },
            [fuel]: null,
            renderFunction: (value) => `(${currencyDisplay(value)})`,
          },
          {
            name: "Charger Incentive",
            ev: {
              renderValue: "charger_incentives",
              fallback: "-",
            },
            [fuel]: null,
            renderFunction: (value) => `(${currencyDisplay(value)})`,
          },
          {
            name: "Infrastructure Upgrade Cost",
            ev: {
              renderValue: "infrastructure_cost",
              fallback: "N/A",
            },
            [fuel]: null,
            spanWrapper: "small",
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: "Battery Replacement Cost",
            ev: {
              renderValue: "battery_cost",
              fallback: "N/A",
            },
            [fuel]: null,
            spanWrapper: "small",
            renderFunction: (value) => currencyDisplay(value),
          },
        ];
        break;
      case "Operating Expenses":
        dataArray = [
          {
            name: "Total Operating Expenses",
            ev: {
              renderValue: "total_opex",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "total_opex",
              fallback: "N/A",
            },
            spanWrapper: "big",
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: (
              <>
                Total of Interest Payments
                <br />
                (From CAPEX Financing)
              </>
            ),
            ev: {
              renderValue: "interest",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "interest",
              fallback: "N/A",
            },
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: (
              <>
                Total Operating Expenses
                <br />
                (without Interest Payments)
              </>
            ),
            ev: {
              renderValue: "OPEXminusInterest",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "OPEXminusInterest",
              fallback: "N/A",
            },
            spanWrapper: "big",
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: (
              <>
                Total Energy Cost
                <br />
                (Electricity or Fuel)
              </>
            ),
            ev: {
              renderValue: "total_energy_cost",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "total_energy_cost",
              fallback: "N/A",
            },
            spanWrapper: "small",
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: "Fuel Cost",
            ev: null,
            [fuel]: {
              renderValue: "electricity_cost",
              fallback: "N/A",
            },
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: "Electricity Cost",
            ev: {
              renderValue: "electricity_cost",
              fallback: "N/A",
            },
            [fuel]: null,
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: (
              <>
                Demand Charges
                <br />
                (Electricity)
              </>
            ),
            ev: {
              renderValue: "demand_charge",
              fallback: currencyDisplay(0), //todo: shouldn't this one's fallback be zero?
            },
            [fuel]: null,
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: "Maintenance Cost",
            ev: {
              renderValue: "maintenance_cost",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "maintenance_cost",
              fallback: "N/A",
            },
            spanWrapper: "small",
            renderFunction: (value) => currencyDisplay(value),
          },
        ];
        break;
      case "Interest Payments":
        dataArray = [
          {
            name: "Annual Interest Payments",
            ev: {
              renderValue: "annual_interest",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "annual_interest",
              fallback: "N/A",
            },
            renderFunction: (value) => currencyDisplay(value),
          },
          {
            name: "Monthly Interest Payments",
            ev: {
              renderValue: "monthly_interest",
              fallback: "N/A",
            },
            [fuel]: {
              renderValue: "monthly_interest",
              fallback: "N/A",
            },
            renderFunction: (value) => currencyDisplay(value),
          },
        ];
        break;
      case "Fuel Consumption":
        dataArray = [
          {
            name: "Electric (kWh/yr)",
            ev: {
              renderValue: "fuel_usage",
              fallback: "N/A",
            },
            [fuel]: null,
            renderFunction: (value) => numberUS.format(roundNumber(value)),
          },
          {
            name: `${selectedFuel} (${unitVolumeMap[units]}s/yr)`,
            ev: null,
            [fuel]: {
              renderValue: "fuel_usage",
              fallback: "N/A",
            },
            renderFunction: (value) =>
              numberUS.format(roundNumber(unitGallons(value), 0)),
          },
        ];
        break;
      case "Grid Emissions":
        dataArray = [
          {
            name: (
              <>
                CO<sub>2</sub>e ({unitPoundMapAbbr[units]}/yr)
              </>
            ),
            ev: {
              renderValue: "grid_emissions[CO2]",
              fallback: "N/A",
            },
            [fuel]: "0.0",
            renderFunction: (value) =>
              numberUS.format(roundNumber(unitPound(value), 1)),
          },
        ];
        break;
      case "Tailpipe Emissions":
        dataArray = [
          {
            name: (
              <>
                CO<sub>2</sub>e ({unitPoundMapAbbr[units]}/yr)
              </>
            ),
            ev: "0.0",
            [fuel]: {
              renderValue: "grid_emissions[CO2]",
              fallback: "N/A",
            },
            renderFunction: (value) =>
              numberUS.format(roundNumber(unitPound(value), 1)),
          },
        ];
        break;
    }
    console.log(fleetSizes);
    const dataObject = dataArray.map((el) => {
      let subObject = { name: el.name, style: customRowStyle[el?.spanWrapper] };
      tableData.map((row, index) => {
        if (el.ev === "0.0") {
          subObject["ev" + index] = el.ev;
        } else if (el.ev) {
          const bevVal = getValueFromNestedObject(
            row.BEV,
            el.ev.renderValue,
            el.ev.fallbackEv,
            fleetSizes[index].total_ev_fleet_size,
            el.renderFunction
          );
          subObject["ev" + index] = bevVal;
        } else {
          subObject["ev" + index] = "-";
        }

        if (el[fuel] === "0.0") {
          subObject[fuel + index] = el[fuel];
        } else if (el[fuel]) {
          const bevIce = getValueFromNestedObject(
            row[selectedFuel],
            el[fuel].renderValue,
            el[fuel].fallbackEv,
            fleetSizes[index].total_ice_fleet_size,
            el.renderFunction
          );
          subObject[fuel + index] = bevIce;
        } else {
          subObject[fuel + index] = "-";
        }
      });
      return subObject;
    });

    return dataObject;
  }

  /**
   * material table data array
   */
  const data = [
    {
      category: (
        <>
          Cost of Ownership
          <br />
          Over {tcoLife} Year Life
        </>
      ),
      rowSpan: 1,
      subcategory: generateDataObj("Cost of Ownership"),
    },
    {
      category: (
        <>
          Capital Expenses
          <br />
          (CAPEX)
        </>
      ),
      rowSpan: 4,
      subcategory: generateDataObj("Capital Expenses"),
    },
    {
      category: (
        <>
          Operating Expenses
          <br />
          (OPEX)
        </>
      ),
      rowSpan: 6,
      subcategory: generateDataObj("Operating Expenses"),
    },
    {
      category: "Interest Payments",
      rowSpan: 1,
      subcategory: generateDataObj("Interest Payments"),
    },
    {
      category: "Fuel Consumption",
      rowSpan: 2,
      subcategory: generateDataObj("Fuel Consumption"),
    },
    {
      category: "Grid Emissions",
      rowSpan: 1,
      subcategory: generateDataObj("Grid Emissions"),
    },
    {
      category: "Tailpipe Emissions",
      rowSpan: 1,
      subcategory: generateDataObj("Tailpipe Emissions"),
    },
  ];
  console.log(
    sim,
    +data?.analysis_type_steps?.battery_sizing?.fleet_and_charger_sizing_analysis?.split(
      "."
    )?.[0] >= 2
  );
  return (
    <>
      {sim && (
        <Dialog
          open={simulationInfoOpen}
          onClose={() => setSimulationInfoOpen(false)}
        >
          <DialogTitle>Analysis: {sim.name}</DialogTitle>
          <DialogContent>
            {sim.description ? (
              <div style={{ marginBottom: "2%" }}>
                <Typography component="span" className="boldText">
                  Description:
                </Typography>
                <Typography component="span">{sim.description}</Typography>
              </div>
            ) : undefined}
            {sim.analysis_type ? (
              <div style={{ marginBottom: "2%" }}>
                <Typography component="span" className="boldText">
                  Fleet Size (BEV):
                </Typography>
                <Typography component="span">
                  {isV2s25d
                    ? sim.steps.evAssessment.fleet_size.feasible
                        .total_ev_fleet_size
                    : sim.steps.evAssessment.fleet_size.total_ev_fleet_size}
                </Typography>
              </div>
            ) : undefined}
            <div style={{ marginBottom: "2%" }}>
              <Typography component="span" className="boldText">
                Fleet Size ({selectedFuel}):
              </Typography>
              <Typography component="span">
                {isV2s25d
                  ? sim.steps.evAssessment.fleet_size.feasible
                      .total_ice_fleet_size
                  : sim.steps.evAssessment.fleet_size.total_ice_fleet_size}
              </Typography>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <Typography component="span" className="boldText">
                charger Count:
              </Typography>
              <Typography component="span">
                {sim.steps.evAssessment.num_chargers || "undefined"}
              </Typography>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <Typography component="span" className="boldText">
                charger Size:
              </Typography>
              <Typography component="span">
                {
                  +sim?.analysis_type_steps?.battery_sizing?.fleet_and_charger_sizing_analysis?.split(
                    "."
                  )?.[0] >= 2
                    ? sim.steps.fleetSizing?.feasible_blocks?.length >
                      +sim.steps.input?.fleetSizing?.row
                      ? //if the index is in range
                        sim.steps.fleetSizing.feasible_blocks[
                          sim.steps.input.fleetSizing.row
                        ].charger_model.rating + " kW"
                      : "NA" //index out of bounds
                    : sim.steps.fleetSizing?.feasible?.combos?.length >
                      sim.steps.input?.fleetSizing?.row
                    ? //if the index is in range
                      sim.steps.fleetSizing.feasible.combos[
                        sim.steps.input.fleetSizing.row
                      ].charger_model.rating + " kW"
                    : "NA" //index out of bounds
                }
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            {/* <Button disabled>More Details</Button> */}
            <Button onClick={() => setSimulationInfoOpen(false)} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <MaterialTable
        title={"Financial Analysis Summary"}
        columns={column2}
        icons={Icons()}
        data={data}
        actions={[
          {
            icon: () => (
              <>
                {isPerVehicle ? (
                  <Typography
                    className="no-print"
                    sx={{ fontSize: "h6.fontSize" }}
                  >
                    All Vehicles
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: "h6.fontSize", fontWeight: "bold" }}
                  >
                    All Vehicles
                  </Typography>
                )}

                <Switch
                  className="no-print"
                  checked={isPerVehicle}
                  color={"primary"}
                />

                {isPerVehicle ? (
                  <Typography
                    sx={{ fontSize: "h6.fontSize", fontWeight: "bold" }}
                  >
                    Per Vehicle
                  </Typography>
                ) : (
                  <Typography
                    className="no-print"
                    sx={{ fontSize: "h6.fontSize" }}
                  >
                    Per Vehicle
                  </Typography>
                )}
              </>
            ),
            tooltip: "Toggle Per Vehicle View",
            isFreeAction: true,
            onClick: () => setIsPerVehicle(!isPerVehicle),
          },
        ]}
        components={{
          Row: ({ data, index: rowIndex }) => (
            <>
              {/* break line between groups of tableRows */}
              <TableRow className="no-print">
                <TableCell />
              </TableRow>
              {data.subcategory.map((val, index) => (
                <TableRow
                  className={
                    index === 0 && (rowIndex === 2 || rowIndex === 3)
                      ? "print-break"
                      : ""
                  }
                  key={index}
                  sx={{
                    backgroundColor:
                      data.tableData.id % 2 == 0 ? "#b6c8f0" : "#dce6fc",
                    height: "100%",
                  }}
                >
                  {index == 0 && (
                    <TableCell
                      rowSpan={data.subcategory.length}
                      sx={{ fontSize: "20px", fontWeight: 600 }}
                    >
                      {data.category}
                    </TableCell>
                  )}
                  {[
                    val.name,
                    ...simulationNames.map((_sim, index) => val[`ev${index}`]),
                    val[`${fuel}${simulationNames.indexOf(selectedSim)}`],
                  ].map((item, index) => (
                    <TableCell
                      key={index} // todo: use a key other than index
                      sx={val.style}
                    >
                      {item}
                    </TableCell>
                  ))}
                  {}
                </TableRow>
              ))}
            </>
          ),
          Container: (containerProps) => (
            <Paper
              {...containerProps}
              className={props.className}
              sx={{
                width: "100%",
                overflow: "hidden",
                backgroundColor: "#FCFCFC",
              }}
              elevation={3}
            />
          ),
        }}
        options={{
          headerStyle: {
            backgroundColor: "#FCFCFC",
            fontWeight: "600",
            fontSize: "20px",
          },
          //set upper limit of rows to 10 (only needs 6 rows, but material table must be 5, 10, or 20 per page)
          pageSize: 10,
          //hide pagination footer
          paging: false,
          //hides the search bar
          search: false,
          //disables the sorting by columns functionality
          maxColumnSort: 0,
          //disables the ability to drag columns around
          draggable: false,
          //prevents the table from changing layout when switching between All/Per vehicles
          tableLayout: "fixed",
          //custom Csv generator, to handle the table's custom data render
          // for material-table's defaultExportCsv, see https://github.com/mbrn/material-table/blob/master/src/components/m-table-toolbar.js#58
          exportMenu: [
            {
              label: "ExportCSV",
              exportFunc: (columns, renderData) => {
                const fileName = `Financial Analysis Summary`;
                let csvData = [];

                const allColumns = columns.reduce((result, column) => {
                  if (typeof column.title === "string") {
                    result.push(column.title);
                  }
                  return result;
                }, []);

                const csvColumns = [...allColumns];

                simulationNames.map((el) => {
                  csvColumns.push(el + " Electric Vehicle");
                });

                simulationNames.map((el) => {
                  csvColumns.push(`${el} ${selectedFuel}`);
                });

                csvData = [
                  csvColumns,
                  [
                    "Fleet Size",
                    ,
                    ...fleetSizes.map(
                      (fleetSize) => fleetSize.total_ev_fleet_size
                    ),
                    ...fleetSizes.map(
                      (fleetSize) => fleetSize.total_ice_fleet_size
                    ),
                  ],
                ];

                data.forEach((category) => {
                  category.subcategory.forEach((subcategory, index) => {
                    let row = [];
                    //handle category names
                    if (index === 0) row.push(jsxToString(category.category));
                    else row.push("");

                    row.push(jsxToString(subcategory.name));

                    simulationNames.forEach((_sim, index) =>
                      row.push(
                        jsxToString(subcategory[`ev${index}`]).replace("-", "")
                      )
                    );
                    simulationNames.forEach((_sim, index) =>
                      row.push(
                        jsxToString(subcategory[`${fuel}${index}`]).replace(
                          "-",
                          ""
                        )
                      )
                    );

                    csvData.push(row);
                  });
                });
                exportCSV(csvData, fileName);
              },
            },
          ],
        }}
      />
    </>
  );
}
